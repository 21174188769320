html{
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.contenedorgris {
  background-color: #808080; /* Color gris en hexadecimal */
  width: 100%; /* O el ancho que desees */
  height: 100px; /* O la altura que desees */}


/* SIDEBAR */
:root {
--header-height: 3rem;
--nav-width: 68px;

--first-color: #1E4162;
--first-color-alt: #4c83b7;
--white-color: #E3E5E5;
}

* {
margin: 0;
padding: 0;
box-sizing: border-box;
}
a {
    text-decoration: none;
}

ul {
list-style: none;
}

main {
position: relative;
margin: var(--header-height) 0 0 0;
padding: 0 1rem;
font-size: 1rem;
font-family: 'Roboto', sans-serif;
transition: 0.5s;
}

.header {
position: fixed;
top: 0;
left: 0;
height: var(--header-height);
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
background-color: var(--white-color);
padding: 0 1rem;
transition: 0.5s;
z-index: 1000; 
}
.bg-gris{
  background-color: var(--white-color);
}

.header-toggle {
font-size: 1.25rem;
cursor: pointer;
color: var(--first-color);
}

.sidebar {
position: fixed;
top: 0;
left: -30%;
height: 100vh;
width: var(--nav-width);
background-color: var(--first-color);
padding-top: 1rem;
transition: 0.5s;
z-index: 1000; 
}

.nav {
display: flex;
flex-direction: column;
justify-content: space-between;
overflow: hidden;
height: 100%;
}

.nav-logo,
.nav-link {
display: grid;
grid-template-columns: max-content max-content;
column-gap: 2rem;
padding: 0.5rem 0 0.5rem 1.5rem;
}

.nav-logo {
margin-bottom: 2rem;
}

.nav-logo-icon {
font-size: 1.25rem;
color: var(--white-color);
cursor: pointer;
}

.nav-logo-name {
font-weight: 600;
color: var(--white-color);
}

.nav-link {
  position: relative;
  color: var(--first-color-alt);
  transition: 0.1s;
  margin-bottom: 1.5rem;
}

.nav-logo-link {
  text-align: center;
  margin: auto;
  margin-top: 2px;
  width: 20px;
}

.nav-link:hover {
color: var(--white-color);
background-color: rgba(0, 0, 0, 0.1);
}

.nav-link.active {
color: var(--white-color);
background-color: rgba(0, 0, 0, 0.1);
}

.nav-link.active::before {
content: '';
position: absolute;
left: 0;
top: 0;
height: 38px;
width: 3px;
background-color: var(--white-color);
transition: 0.1s;
}

.show {
left: 0;
}

.space-toggle {
padding-left: calc(var(--nav-width) + 1rem);
}

@media screen and (min-width: 768px) {
main {
    margin: calc(var(--header-height) + 1rem) 0 0 0;
    padding: 0 1rem 0 calc(var(--nav-width) + 1rem);
}

.header {
    height: calc(var(--header-height) + 1rem);
    padding-left: calc(var(--nav-width) + 1rem);
}

.sidebar {
    left: 0;
    padding: 1rem 0 0 0;
}

.show {
    width: calc(var(--header-height) + 156px);
}

.space-toggle {
    padding-left: calc(var(--nav-width) + 156px );
}
}

/* MODAL */

.modal {
  --spacing: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modal__close {
  display: block;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal__inner {
  display: flex;
  justify-content: center;
  position: relative;
  /* width: calc(var(--spacing) ); */
  max-width: 100%;
  padding: calc(var(--spacing)) calc(var(--spacing));
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  /* box-shadow: rgba(9, 30, 66, 0.25) 0px 20px 32px -8px; */
}

.modal__close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #444;
  vertical-align: text-top;
}
/* 
.modal__close-btn {
  width: var(--spacing);
  height: var(--spacing);
  fill: currentColor;
} */

.modal__close-btn:hover {
  opacity: 3;
  color: #000000;
}

.logo-login {
  height: 100px;
}

.we-10 {
  right: 10% !important;
}

.ws-10 {
  left: 10% !important;
}

.u-img {
  height: 450px;
  max-width: 850px;
  background: url(../src/img/facultad-odontologia.svg);
}
.card-login{
  background-color: #fff;
  width: 500px;
}

@media screen and (max-width: 768px) {
  .we-10 {
    right: 0% !important;
    left: 0% !important;
  }

  .ws-10 {
    right: 0% !important;
    left: 0% !important;
  }

  .card-login{
    background-color: #ffffffbf;
    width: 100%;
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: center; 
  }
  
  .logo-login {
    height: 70px;
  }

  .u-img {
    height: 450px;
    max-width: 850px;
    background: url(../src/img/facultad-odontologia.svg);
  }
}
